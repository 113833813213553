import axios from 'axios'

const path = '/suscripciones/'

export function getSuscripcionByToken (token) {
  return axios.get(path + token)
  .then(response => {
    return response.data
  })
}

export function generatePaymentData (formData) {
  return axios.post(path + 'generar-informacion-medio-pago', formData)
  .then(response => {
    return response.data
  })
}

/* export function pagoSuscripcion (token, transaccion) {
  return axios.put(path + 'pago/' + token, transaccion)
  .then(response => {
      return response.data
    })
} */

export function getSuscripcionesMiembro (idMiembro) {
  return axios.get(path + 'miembro/' + idMiembro)
  .then(response => {
    return response.data
  })
}

export function deleteSuscripcion (id) {
  return axios.delete(path + id)
  .then(response => {
      return response.data
    })
}

export function cambiarGrupoDeSuscripcion (data) {
  return axios.put(path + 'cambiar-grupo', data)
  .then(response => {
      return response.data
    })
}

export function pagoManual (idMiembro) {
  return axios.put(path + 'pago-manual/' + idMiembro)
  .then(response => {
      return response.data
    })
}

export function anularPago (idMiembro) {
  return axios.put(path + 'anular-pago/' + idMiembro)
  .then(response => {
      return response.data
    })
}

export function uploadFile (payload) {
  return axios.post(path + 'file',
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  ).then(response => {
    return response.data
  })
}

export function verificarAltaComprobanteDeRenovacion (idMiembro) {
  return axios.get(path + 'verificar-alta-comprobante/' + idMiembro)
  .then(response => {
      return response.data
    })
}

export function downloadFile (name) {
  return axios({
    method: 'get',
    url: path + 'file/' + name,
    responseType: 'blob',
  })
  .then(response => {
      return response
    })
}

export default {
  getSuscripcionByToken,
  generatePaymentData,
  /* pagoSuscripcion, */
  getSuscripcionesMiembro,
  deleteSuscripcion,
  cambiarGrupoDeSuscripcion,
  pagoManual,
  anularPago,
  uploadFile,
  verificarAltaComprobanteDeRenovacion,
  downloadFile,
}
